import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { SimplePage } from '@components/global/SimplePage';
import { MarkdownRemark } from '@model/generated';
import { Meta } from '@components/global/Meta';

type DataProps = {
  markdown: MarkdownRemark;
};

type Props = PageProps<DataProps>;

const Impressum: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <SimplePage
      title={data.markdown.frontmatter.title}
      description={data.markdown.frontmatter.description}
    >
      <div
        className="markdown"
        dangerouslySetInnerHTML={{ __html: data.markdown.html }}
      />
    </SimplePage>
  );
};

export const Head = () => {
  return <Meta />;
};

export default Impressum;

export const query = graphql`
  query {
    markdown: markdownRemark(fileAbsolutePath: { regex: "/impressum.md$/" }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
